import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { TOKEN } from './../../shared/utilities/defines/index';
import { StorageService } from './../../shared/services/storage/storage.service';
import { CustomerAddress } from './../../swagger/model/customerAddress';
import { Router } from '@angular/router';
import { Injectable, OnInit, ErrorHandler } from '@angular/core';
import { Cart } from '../../swagger/model/cart';
import { CustomerService } from '../../swagger/api/customer.service';
import { CartService } from '../../swagger/model/cartService';
import { Category } from '../../swagger/model/category';
import { RemoveFromCartRequest } from '../../swagger/model/removeFromCartRequest';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { AvailableSlotsResponse } from '../../swagger/model/availableSlotsResponse';
import { CartCoupon, AvailableSlot, CategoryReceipt } from '../../swagger';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { routeConfig } from '../../shared/utilities/pages-config';

export interface MyCarts {
    receipt?: CategoryReceipt;
    categoryName: string;
    cartService?: Array<CartService>;
    orderDate?: string;
    orderTime?: string;
    orderDateTime?: string;
    category?: Category;
    timeIsInvalid?: boolean;
    couponCode?: string;
    couponValid?: boolean;
    couponLoading?: boolean;
    availablesSlots?: AvailableSlotsResponse;
    availablesSlotsLoading?: boolean;
    couponErrorMessage?: string;
    coupon?: CartCoupon;
    nextAvailableSlot?: AvailableSlot;
    nextAvailableSlotLoading?: boolean;
}

@Injectable()
export class CartsService implements OnInit {

    cart: Cart;
    cartService: MyCarts[];
    totalPrice: number;
    toastConfig: Partial<ToastrConfig> = {
        timeOut: 10000,
    };
    address?: CustomerAddress;
    token: string;


    constructor(
        private customerService: CustomerService,
        private toastr: ToastrService,
        private storageService: StorageService,
        private router: Router,
        private translationService: TranslateService,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.token = this.storageService.getStorage(TOKEN);
        this.getCarts();
    }


    getCarts() {
        this.token = this.storageService.getStorage(TOKEN);
        this.customerService.getCart(this.token).pipe(debounceTime(500), distinctUntilChanged()).subscribe(
            res => {
                this.cart = res;
                this.filterServices(res);
                if ((res.services.length === 0 && res.inspectionCategories.length === 0)
                    && (this.router.url === routeConfig.create_order.first_step.route
                        || this.router.url === routeConfig.create_order.second_step.route)) {
                    this.router.navigate([routeConfig.services.route]);
                }
                this.authenticationService.isAnonymous.complete();
                return res;
            },
            err => {
                return null;
            }
        );
        return null;

    }

    filterServices(cart: Cart) {
        let oldDateTime: MyCarts[];
        moment.locale('en');
        if (this.cartService) {
            oldDateTime = this.cartService.filter(category => category.orderDateTime);
        }
        this.cartService = [];
        this.address = cart.address;
        const categories: string[] = [];
        if (cart) {
            for (const service of cart.services) {
                this.totalPrice = cart.totalPrice;
                if (!categories.includes(service.rootCategory.categoryId)) {
                    categories.push(service.rootCategory.categoryId);
                }
            }
            for (const inspection of cart.inspectionCategories) {
                this.totalPrice = cart.totalPrice;
                if (!categories.includes(inspection.categoryId)) {
                    categories.push(inspection.categoryId);
                }
            }
            for (const cat of categories) {
                if (cart.services.filter(i => i.rootCategory.categoryId === cat)[0]) {
                    const thisCategory: MyCarts = {
                        categoryName: cart.services.find(i => i.rootCategory.categoryId === cat).rootCategory.nameAr,
                        cartService: cart.services.filter(i => i.rootCategory.categoryId === cat),
                        category: cart.services.filter(i => i.rootCategory.categoryId === cat)[0].rootCategory,
                        orderTime: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderTime : '00',
                        orderDate: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderDate : '',
                        orderDateTime: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderDateTime : '00',
                        couponLoading: false,
                        couponValid: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].couponValid : undefined,
                        couponCode: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].couponCode : '',
                        coupon: cart.coupons.find(i => i.categoryId === cat),
                    };
                    // thisCategory.couponCode = thisCategory.coupon ? thisCategory.coupon.code : undefined;
                    if ((!thisCategory.orderDateTime || thisCategory.orderDateTime === '00') && thisCategory.coupon) {
                        thisCategory.orderDateTime = thisCategory.coupon.time;
                        thisCategory.orderDate = moment(thisCategory.coupon.time).toDate();
                        thisCategory.orderTime = thisCategory.coupon.time;
                    }
                    this.cartService.push(thisCategory);
                } else {
                    const thisCategory: MyCarts = {
                        categoryName: cart.inspectionCategories.find(i => i.categoryId === cat).nameAr,
                        category: cart.inspectionCategories.filter(i => i.categoryId === cat)[0],
                        orderTime: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderTime : '00',
                        orderDate: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderDate : '',
                        orderDateTime: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].orderDateTime : '00',
                        couponValid: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].couponValid : undefined,
                        couponCode: oldDateTime && oldDateTime.filter(category => category.category.categoryId === cat)[0] ?
                            oldDateTime.filter(category => category.category.categoryId === cat)[0].couponCode : '',
                        couponLoading: false,
                        coupon: cart.coupons.find(i => i.categoryId === cat),
                    };
                    // thisCategory.couponCode = thisCategory.coupon ? thisCategory.coupon.code : undefined;
                    if ((!thisCategory.orderDateTime || thisCategory.orderDateTime === '00') && thisCategory.coupon) {
                        thisCategory.orderDateTime = thisCategory.coupon.time;
                        thisCategory.orderDate = moment(thisCategory.coupon.time).toDate();
                        thisCategory.orderTime = thisCategory.coupon.time;
                        thisCategory.couponValid = true;
                    }
                    this.cartService.push(thisCategory);
                }
                for(let cartCategory of this.cartService){
                    for(let cartReceipt of cart.receipts){
                        if(cartReceipt.categoryId == cartCategory.category.categoryId){
                            cartCategory.receipt = cartReceipt;
                        }
                    }
                }
            }
            if (this.cartService.length === 0 && (this.router.url === routeConfig.create_order.first_step.route ||
                this.router.url === routeConfig.create_order.second_step.route)) {
                this.router.navigate([routeConfig.services.route]);
            }
        }
    }

    removeFromCart(serviceId) {
        this.token = this.storageService.getStorage(TOKEN);
        const removeCartReq: RemoveFromCartRequest = {
            servicesIds: [serviceId],
        };
        this.customerService.removeFromCart(this.token, removeCartReq).subscribe(
            res => {
                this.cart = res;
                this.filterServices(res);
            },
            err => {
                this.toastr.error(this.translationService.instant('please_try_later'),
                    this.translationService.instant('an_error_occurred'), this.toastConfig);
            },
        );
    }

    removeInspection(categortyId) {
        this.token = this.storageService.getStorage(TOKEN);
        this.customerService.removeInspectionFromCart(categortyId, this.token).subscribe(
            res => {
                this.cart = res;
                this.filterServices(res);
            },
            err => {
                this.toastr.error(this.translationService.instant('please_try_later'),
                    this.translationService.instant('an_error_occurred'), this.toastConfig);
            },
        );
    }

}
