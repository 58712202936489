import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../helpers/translate service/language.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { routeConfig } from '../../shared/utilities/pages-config';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public isLoading = true;
  public toggleFactors: Boolean;
  public form: FormGroup;
  public isSubmitLoading = false;
  public paidAmount: number;
  public success: boolean;
  public currency: string;
  public merchant_order_id: string;
  public data_message: string;
  
  constructor(
    private route: ActivatedRoute,
    public languageService: LanguageService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        if (params['success']) {
          let successParam = params['success'];
          this.success = (successParam.toLowerCase() =="true")?true:false;
        }
        if (params['amount_cents']) {
          this.paidAmount = (params['amount_cents']/100);
        }
        if (params['currency']) {
          this.currency = params['currency'];
        }
        if (params['merchant_order_id']) {
          this.merchant_order_id = params['merchant_order_id'];
        }
        if (params['data_message']) {
          this.data_message = params['data_message'];
        }
      }
    );
  }

 

 
}
