import { FkdToastrComponent } from './shared/components/fkd-toastr/fkd-toastr-component';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './shared/services/authenication/authentication.service';
import { RouteInterceptorService } from './shared/services/route-interceptor/route-interceptor.service';
import { LanguageService } from './helpers/translate service/language.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from '@angular/core';


import { AppComponent } from './app.component';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserModule } from './user/user.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerService } from './swagger/api/customer.service';
import { BASE_PATH } from './swagger/variables';
import { environment } from '../environments/environment';
import { AppRoutingModule, routedComponents } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { angularxProvideConfig } from './app-configs';
import { SharedUiModule } from './sharedui/sharedui.module';
import { AuthGuard } from './core/auth/auth-guard.service';
import { AnonymousGuard } from './core/auth/anonymous-guard.service';
import { LandingPageGuard } from './core/auth/landingpage-guard.service';
import { CartsService } from './home/sharedServices/carts.service';
import { OrderService } from './home/sharedServices/order.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CategoryServicesModalComponent } from './home/components/category-services-modal/category-services-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HelpersModule } from './helpers/helpers.module';
import { UserService } from './user/services/user.service';
import { AccordionModule, TooltipModule } from 'ngx-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { GA_TOKEN, GoogoleAnalyticsService } from './shared/services/google-analytics/googole-analytics.service';
import { EventsService } from './shared/services/events/events.service';
import { GTMService } from './shared/services/gtm/gtm.service';
// redux
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomSerializer, reducers, Effects } from './store';
// rollbar
import * as Rollbar from 'rollbar';
import * as RollbarConfigurations from './shared/services/rollbar/rollbar.service';
import { EventsInterceptor } from './shared/services/interceptors/events-interceptor';
import { ErrorHttpInterceptor } from './shared/services/interceptors/http-interceptor';
import { RollbarErrorHandler } from './shared/services/rollbar/rollbar.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared';
import { RatingModule } from './rating/rating.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectLocationComponent } from './landing/components/select-location/select-location.component';
import { BrandingLandingModule } from './branding-landing/branding-landing.module';
// firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { RouterModule } from '@angular/router';
import { PaymentModule } from './payment/payment.module';
/**
 * rollbar Configuration Factory
 */

export function rollbarFactory() {
  return new Rollbar(RollbarConfigurations.Config);
}
export function HttpLoaderFactory(httpClient: HttpClient) {
  const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json?v=' + random);
}
@NgModule({
  declarations: [
    AppComponent,
    ...routedComponents,
    CategoryServicesModalComponent,
    SelectLocationComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    AuthenticationModule,
    RatingModule,
    PaymentModule,
    SocialLoginModule,
    BrandingLandingModule,
    UserModule,
    NgSelectModule,
    ToastrModule.forRoot({
      toastComponent: FkdToastrComponent,
      maxOpened: 1
    }),
    SharedUiModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    HelpersModule,
    AngularFirePerformanceModule,
    AngularFireModule.initializeApp(environment.firebase),
    StoreModule.forRoot(reducers), // to initialize the root reducers
    EffectsModule.forRoot(Effects), // to initialize the root effects
    StoreRouterConnectingModule, // for Redux routing
    !environment.production ? StoreDevtoolsModule.instrument() : [] // for redux debugging
  ],
  providers: [
    RouteInterceptorService,
    { provide: ErrorHandler, useClass: RollbarConfigurations.RollbarErrorHandler },
    { provide: RollbarConfigurations.RollbarService, useFactory: rollbarFactory },
    MixpanelService,
    CustomerService,
    { provide: BASE_PATH, useValue: environment.api_base_url },
    { provide: AuthServiceConfig, useFactory: angularxProvideConfig },
    AuthGuard,
    RollbarErrorHandler,
    AnonymousGuard,
    LandingPageGuard,
    CartsService,
    OrderService,
    UserService,
    LanguageService,
    EventsService,
    AuthenticationService,
    GTMService,
    GoogoleAnalyticsService,
    { provide: GA_TOKEN, useValue: environment.google_analytics_key },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: EventsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },

  ],
  entryComponents: [CategoryServicesModalComponent, FkdToastrComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
