import { HelpersModule } from './../helpers/helpers.module';
import { TestomonialsComponent } from './components/testomonials/testomonials.component';
import { NgModule } from '@angular/core';
import { MainHeaderComponent } from './components/header/header.component';
import { UserModule } from '../user/user.module';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HelpComponent } from './components/help/help.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { RouterModule } from '@angular/router';
import { PricesComponent } from './components/prices/prices.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { FormsModule, ReactiveFormsModule } from '../../../node_modules/@angular/forms';
import { ContactusComponent } from './components/contactus/contactus.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SeoComponent } from './components/seo/seo.component';
import { SharedModule } from '../shared/shared.module';
import { CovidComponent } from './components/covid/covid.component';

@NgModule({
    imports: [
        CommonModule,
        UserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        CollapseModule,
        AccordionModule,
        TranslateModule,
        HelpersModule,
        SharedModule
    ],
    declarations: [
        MainHeaderComponent,
        FooterComponent,
        SeoComponent,
        HelpComponent,
        CovidComponent,
        TermsComponent,
        PrivacyComponent,
        PricesComponent,
        TestomonialsComponent,
        AboutusComponent,
        ContactusComponent,
    ],
    exports: [
        MainHeaderComponent,
        FooterComponent,
        SeoComponent,
        HelpComponent,
        CovidComponent,
        TermsComponent,
        PricesComponent,
        TestomonialsComponent,
    ],
})
export class SharedUiModule {

}
