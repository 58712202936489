import { FkdToastrComponent } from './components/fkd-toastr/fkd-toastr-component';
import { PluralTranslatePipe } from './pipes/plural-translate.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DatePipe, DateAddPipe } from './pipes/dateToAr.pipe';
import { FkdDebounceClickDirective } from './directives/fkd-debounce-click.directive';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpModule } from '@angular/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FkdRadioButtonComponent } from './components/fkd-radio-button/fkd-radio-button.component';
import { RateComponent } from './components/rate/rate.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FkdCheckboxComponent } from './components/fkd-checkbox/fkd-checkbox.component';
import { FkdTextareaComponent } from './components/fkd-textarea/fkd-textarea.component';
import { FkdTextboxComponent } from './components/fkd-textbox/fkd-textbox.component';
import { FkdCarouselComponent } from './components/fkd-carousel/fkd-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AutoplayVideoDirective } from './directives/fkd-video-play.directive';
import { FkdClickOutsideDirective } from './directives/fkd-click-outside.directive';
import { PaymentMethodsCarouselComponent } from './components/payment-methods-carousel/payment-methods-carousel.component';
/* Custom Components */
const components = [
  FkdRadioButtonComponent,
  RateComponent,
  FkdCheckboxComponent,
  FkdTextareaComponent,
  FkdTextboxComponent,
  FkdToastrComponent,
  FkdCarouselComponent,
  PaymentMethodsCarouselComponent
];

const directives = [
  FkdDebounceClickDirective,
  AutoplayVideoDirective,
  FkdClickOutsideDirective
];

const pipes = [
  DatePipe,
  FilterPipe,
  DateAddPipe,
  PluralTranslatePipe

];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PerfectScrollbarModule,
    RatingModule.forRoot(),
    HttpModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    CarouselModule
  ],
  providers: [
  ],
  exports: [
    // Modules
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    // Third Party Modules
    TabsModule,
    PerfectScrollbarModule,
    BsDatepickerModule,
    TimepickerModule,
    AccordionModule,
    TooltipModule,
    ...components,
    ...directives,
    ...pipes
  ]
})
export class SharedModule { }
