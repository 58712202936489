import { USER_LANGUAGE, DEFAULT_LANGUAGE } from './../../shared/utilities/defines/index';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../shared/services/storage/storage.service';
import * as moment from 'moment-timezone';
import { LocaleData } from 'ngx-bootstrap';
@Injectable()
export class LanguageService {
  /** Languages Changed */
  public languageChange: Subject<string> = new Subject<string>();
  public languageChanged$ = this.languageChange.asObservable();
  public currentLanguage: any;
  constructor(private translateService: TranslateService,
    private storageService: StorageService) { 

      this.currentLanguage = this.storageService.getStorage(USER_LANGUAGE);
      if(!this.currentLanguage){
        this.setLanguage(null);
      }
    }


  /**
   * set default language for the user
   */
  setLanguage(lang_code?: string) {
    const code = this.storageService.getStorage(USER_LANGUAGE);

    if (lang_code || code) {
      if (lang_code && lang_code !== code) {
        this.storageService.setStorage(USER_LANGUAGE, lang_code.toLowerCase());
        this.translateService.use(lang_code.toLowerCase());
        this.translateService.currentLang = lang_code.toLowerCase();
        this.currentLanguage = this.translateService.currentLang;

      } else {
        this.storageService.setStorage(USER_LANGUAGE, code.toLowerCase());
        this.translateService.use(code.toLowerCase());
        this.translateService.currentLang = code.toLowerCase();
        this.currentLanguage = this.translateService.currentLang;

      }
    } else {
      this.storageService.setStorage(USER_LANGUAGE, DEFAULT_LANGUAGE);
      this.translateService.use(DEFAULT_LANGUAGE);
      this.translateService.currentLang = DEFAULT_LANGUAGE;
      this.currentLanguage = this.translateService.currentLang;

    }
    /** get the week start date with language in moment locale*/
    this.languageChange.next(this.translateService.currentLang);
  }

  /** Get Custom LocaleData */
  getCustomLocaleData(key: string) {
    const langLD = new Object() as LocaleData;
    const momentLang = moment().locale(key).localeData();
    langLD.abbr = key;
    langLD.isPM = momentLang.isPM;
    langLD.invalidDate = momentLang.invalidDate();
    langLD.months = momentLang.months();
    langLD.weekdays = momentLang.weekdays();
    langLD.weekdaysShort = momentLang.weekdaysShort();
    return langLD;
  }

}
